import { CanActivateFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { StatusPageService, StatusSummary } from '../services/status-page.service';
import { catchError, map } from 'rxjs/operators';
import { inject } from '@angular/core';

export const maintenanceGuard: CanActivateFn = () => {
    const statusPage = inject(StatusPageService);
    const router = inject(Router);

    return statusPage.fetchStatus().pipe(
        map(
            (status) => checkStatus(status, router),
            catchError(() => of(true))
        )
    );
};

function checkStatus(status: StatusSummary, router: Router): boolean {
    const ignoreFlag = localStorage.getItem('ignore_statuspage');
    if (ignoreFlag === 'true') {
        return true;
    }
    switch (status.status) {
        case 'major_outage':
        case 'under_maintenance': {
            router.navigate(['maintenance'], {
                state: status,
            });
            return false;
        }

        default:
            return true;
    }
}
