<div *ngIf="problem">{{ problem }}</div>

<div *ngIf="!problem" [hidden]="loading" fxFlexFill>
    <div class="root">
        <div class="quest">
            <vi-quest [model]="quest" (check)="check($event)" (partChange)="page($event)"></vi-quest>
        </div>

        <div class="footer" *ngIf="showRecommendation">
            <mat-progress-bar value="100" mode="determinate" color="warn"></mat-progress-bar>

            <div class="buttons">
                <button mat-mini-fab disabled>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <div
                    class="restart"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="10px"
                    tabindex="0"
                    role="button"
                    (click)="reloadProductFinder()"
                    (keydown.enter)="reloadProductFinder()"
                    (keydown.space)="reloadProductFinder()"
                >
                    <img src="../../assets/icons/refresh-icon.png" alt="{{ 'quest.button.start.over' | translate }}" />
                    <span> {{ 'quest.button.start.over' | translate }}</span>
                </div>

                <button mat-mini-fab disabled>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>

<div class="bounce-container" *ngIf="loading && !problem">
    <div class="bouncing-circle"></div>
    <div class="bouncing-circle"></div>
</div>
