import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
    Quest,
    QuestCheckResult,
    QuestDisplayType,
    QuestPartChange,
    QuestQuestion,
    QuestQuestionGroup,
    QuestValueChange,
} from '@vi/quest';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ConfigitQuestAdapterService } from '../configit-quest-adapter/configit-quest-adapter.service';
import { ConfigurationService } from '../services/configuration.service';
import { HttpParams } from '@angular/common/http';
import { ConfigitAssignment } from '../configit-quest-adapter/configit.model';
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const flatten = (arr: any[][]) => arr.reduce((prev, curr) => prev.concat(curr), []);

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
    public quest: Subject<Quest> = new Subject<Quest>();
    public problem?: string;
    public showRecommendation = false;

    public redirect?: string | null;

    public loading = true;

    private submitModel: Quest;
    private selectedProducts: string;
    private selectedEnergySources: string | undefined;

    constructor(
        private quests: ConfigitQuestAdapterService,
        private snackbar: MatSnackBar,
        private translate: TranslateService,
        private configuration: ConfigurationService
    ) {}

    ngOnInit(): void {
        const parameters = new URLSearchParams(window.location.search);
        const lang = parameters.get('lang');
        this.redirect = parameters.get('redirect');

        if (lang) {
            this.translate.setDefaultLang(lang);
            this.configuration.lang = lang;
            this.quests.get(environment.quest.project).subscribe((model: Quest) => this.setModel(model));
        } else {
            this.problem = 'Parameter lang is required. Use it like ?lang=de-DE';
        }
    }
    // Listen only to submit button from Configit
    @HostListener('click', ['$event']) onClick(event: PointerEvent) {
        const element = event.target as HTMLElement;
        if (element.className === 'consultation-btn') {
            const find = '[-_]?NoRecommendation';
            const re = new RegExp(find, 'g');
            this.selectedProducts =
                element.id && element.id.replace(re, '') ? element.id.replace(re, '') : 'NoRecommendation';
            this.selectedEnergySources = element.dataset.energysources
                ? element.dataset.energysources.replace(re, '')
                : 'NoEnergySourcesAvailable';

            this.submit(this.submitModel);
        }
    }

    private recurseGroups(group: QuestQuestionGroup): QuestQuestionGroup[] {
        const subs = (group.subGroups || []).map((g) => this.recurseGroups(g));
        return [group, ...flatten(subs)];
    }

    private setModel(model: Quest) {
        const groups: QuestQuestionGroup[] = flatten(model.parts.map((part) => part.groups || []));
        const allGroups = flatten(groups.map((g) => this.recurseGroups(g)));

        const questions: QuestQuestion[] = [
            ...flatten(model.parts.map((part) => part.questions || [])),
            ...flatten(allGroups.map((g) => g.questions || [])),
        ];
        // First check, if all product relevant questions are answered
        // - yes => show recommendation in single mode / no => continue in singlewizard mode
        const stillQuestionsToAnswer = questions
            ?.filter((question) => !question.id.includes(environment.app.recommendationPartId))
            ?.some((question) => !question.value);

        if (stillQuestionsToAnswer) {
            model.parts = [{ id: 'flat', questions }];
            model.display = QuestDisplayType.wizard;
        } else {
            this.showRecommendation = true;

            questions.forEach((q) => {
                if (q.text === 'Hidden_Title') {
                    q.text = '';
                }
            });

            model.parts = model.parts.filter((part) => part.id === environment.app.recommendationPartId);
            model.display = QuestDisplayType.single;
        }

        this.submitModel = model;
        this.quest.next(model);
        this.loading = false;
    }

    check(changed: QuestValueChange) {
        this.quests
            .check(changed)
            .pipe(map((result: QuestCheckResult) => result.model))
            .subscribe(
                (model: Quest | undefined) => {
                    if (model) {
                        this.setModel(model);
                    } else {
                        throw new Error('missing model');
                    }
                },
                () => this.onError('COMMON.SNACK_BAR.UPDATE_VALUES.ERROR')
            );
    }

    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    page(changed: QuestPartChange) {}

    submit(model: Quest) {
        let assignments: ConfigitAssignment[];

        // Parse HttpParams if needed and navigate to given redirect url in top window
        if (this.redirect) {
            let params = new HttpParams();
            // Get only necessary configit assignments
            assignments = this.getAssignments(model.original.assignments);

            Object.keys(assignments).forEach((key) => {
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                const value = (assignments as any)[key];
                params = params.append(key, value);
            });

            this.selectedEnergySources?.split('-').forEach((energySource) => {
                params = params.append('SelectedEnergySource', energySource);
            });

            if (window.top) {
                window.top.location.href = this.redirect + '?' + params.toString();
            }
        } else {
            assignments = this.getAssignments(model.original.assignments, true);
            if (top) {
                top.postMessage(assignments, '*');
            }
        }
    }

    reloadProductFinder() {
        window.location.reload();
    }

    private getAssignments(originalAssignments: ConfigitAssignment[], getValueNameAndText?: boolean) {
        let selectedValues;
        // Filter unnecessary assignments from configit model, e.g. 'Settings'
        let assignments = originalAssignments.filter(
            (originalAssignment) =>
                !originalAssignment.variableName?.includes('Settings.') &&
                !originalAssignment.variableName?.includes('Disclaimer') &&
                !originalAssignment.valueName?.includes('NoRecommendation')
        );

        if (getValueNameAndText) {
            assignments = assignments.reduce(
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                (prev: any, curr: any) => ({
                    ...prev,
                    [curr.variableName]: { value: curr.valueName, text: curr.valueText },
                }),
                {}
            );
            selectedValues = {
                ...assignments,
                SelectedEnergySources: { value: this.selectedEnergySources, text: this.selectedEnergySources },
                SelectedProducts: { value: this.selectedProducts, text: this.selectedProducts },
            };
        } else {
            assignments = assignments.reduce(
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                (prev: any, curr: any) => ({ ...prev, [curr.variableName]: curr.valueName }),
                {}
            );
            selectedValues = {
                ...assignments,
                SelectedProducts: this.selectedProducts,
            };
        }

        return selectedValues;
    }

    private onError(translationKey: string) {
        // TODO: decide what to do with errors
        this.snackbar.open(translationKey, '', {
            panelClass: ['error-snackbar'],
            duration: 3000,
        });
    }
}
