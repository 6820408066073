import { Component } from '@angular/core';

@Component({
    selector: 'app-how-to',
    templateUrl: './how-to.component.html',
    styleUrls: ['./how-to.component.scss'],
})
export class HowToComponent {
    public boilerplate =
        '\n' +
        '<head>\n' +
        '...\n\n' +
        '  <meta name="viewport" content="width=device-width initial-scale=1">\n\n' +
        '...\n' +
        '</head>\n';

    public iFrame =
        '\n' +
        '<body>\n' +
        '...\n\n' +
        '   <iframe src="https://product-finder.viessmann.com?lang=de-DE&redirect=https://implementing-application.com"></iframe>\n\n' +
        '...\n' +
        '</body>';

    public styling =
        '\n' +
        'body {\n' +
        '   margin: 0;\n' +
        '}\n' +
        '  \n' +
        'iFrame {\n' +
        '   position: absolute;\n' +
        '   top: 0;\n' +
        '   left: 0;\n' +
        '   width: 100%;\n' +
        '   height: 100%;\n' +
        '   border: 0;\n' +
        '}';

    public code =
        '\n' +
        'window.onmessage = function(pfEvent) {\n' +
        '   \n' +
        '       console.log("submit-button clicked!");\n' +
        '       console.log(pfEvent.data);\n' +
        '   \n' +
        '}';

    public assignmentsQuery =
        'MainPage.DE_New.DesiredSolution=Heating&MainPage.DE_New.UseCase=Modernization&MainPage.DE_New.CurrentHeating=Oil&Old.DE.NoRecommendation=Yes&MainPage.DE_New.FutureHeating=Gas&MainPage.DE_New.AddSustainableHeating=No&Recommendation.DE.Products.SystemOrProduct=System&Recommendation.DE.Products.Title=&Recommendation.DE.System1.Title=The%20following%20systems%20fit%20your%20specifications%20very%20well.%20Please%20select%20the%20proposal%20for%20which%20you%20would%20like%20to%20receive%20free%20specialist%20advice%20from%20a%20trained%20Viessmann%20specialist.&Recommendation.DE.System1.Product1=Vitodens%20200-W&Recommendation.DE.System1.Product2=Vitocell-300&Recommendation.DE.System1.NoRecommendation=No&Recommendation.DE.System2.Product1=Vitodens%20300-W&Recommendation.DE.System2.Product2=Vitocell-300&SelectedProducts=Vitodens%20200-W_Vitocell-300';

    public assignmentsJSON = {
        'MainPage.EnergySource': {
            value: 'Gas',
            text: 'Gas',
        },
        'Recommendation.AltProducts': {
            value: 'None',
            text: 'None',
        },
        'MainPage.RentOrBuy': {
            value: 'Buy',
            text: 'Kaufen',
        },
        'MainPage.GenerateElectricity': {
            value: 'No',
            text: 'Nein',
        },
        'MainPage.HeatingSystem': {
            value: 'CentralHeating_WarmWater',
            text: 'Zentralheizung und Warmwasser',
        },
        'MainPage.Mounting': {
            value: 'Wall mounted',
            text: 'Wandhängend',
        },
        'MainPage.Solar': {
            value: 'No',
            text: 'Nein',
        },
        'Information.People': {
            value: '2',
            text: '2',
        },
        'Information.AmountPeople': {
            value: '2',
            text: '2',
        },
        'Information.Bathrooms': {
            value: '2',
            text: '2',
        },
        'Information.WarmWaterDemand': {
            value: 'Little',
            text: 'Wenig',
        },
        'Information.AmountBathrooms': {
            value: '2',
            text: '2',
        },
        'Information.HeatedLivingSpace': {
            value: '100-150m²',
            text: '100 - 150 m²',
        },
        'Recommendation.Products': {
            value: 'Vitodens_222-W',
            text: 'Vitodens 222-W',
        },
        SelectedProducts: {
            value: 'Vitodens 200-W_Vitocell-300',
            text: 'Vitodens 200-W_Vitocell-300',
        },
    };

    constructor() {}
}
