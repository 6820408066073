import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

export type Status = 'major_outage' | 'under_maintenance' | 'partial_outage' | 'degraded_performance' | 'operational';

export interface PlannedMaintenance {
    start_date: string;
    end_date: string;
    remind_from: string;
    status: string;
}

export interface StatusSummary {
    id: string;
    name: string;
    status: Status;
    next_maintenance?: PlannedMaintenance;
}

@Injectable({
    providedIn: 'root',
})
export class StatusPageService {
    constructor(private http: HttpClient) {}

    public fetchStatus(): Observable<StatusSummary> {
        return this.http.get<StatusSummary>(environment.statusPage);
    }
}
