import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    public lang: string;
    private _salesAreaName: string | null;
    private _plant: string | null;
    get salesAreaName(): string | null {
        if (this._salesAreaName) {
            return this._salesAreaName;
        }

        switch (this.lang) {
            case 'de-DE':
                return 'Germany';
            case 'de-AT':
                return 'Austria';
            case 'fr-FR':
            case 'en-FR':
                return 'France';
            case 'fr-BE':
            case 'nl-BE':
                return 'Belgium';
            case 'en-US':
                return 'USA';
            case 'en-CA':
                return 'Canada';
            case 'it-IT':
            case 'en-IT':
                return 'Italy';
            case 'tr-TR':
            case 'en-TR':
                return 'Turkey';
            case 'cs-CZ':
            case 'en-CZ':
                return 'Czech Republic';
            case 'sk-SK':
            case 'en-SK':
                return 'Slovakia';
            case 'pt-PT':
            case 'en-PT':
                return 'Portugal';
            case 'es-ES':
            case 'en-ES':
                return 'Spain';
            case 'ro-RO':
            case 'en-RO':
                return 'Romania';
            case 'en-UA':
            case 'uk-UA':
                return 'Ukraine';
            case 'bg-BG':
            case 'en-BG':
                return 'Bulgaria';
            case 'sl-SI':
            case 'en-SI':
                return 'Slovenia';
            case 'hr-HR':
            case 'en-HR':
                return 'Croatia';
            case 'hu-HU':
            case 'en-HU':
                return 'Hungary';
            case 'sr-RS':
            case 'en-RS':
                return 'Serbia';
            case 'et-EE':
            case 'en-EE':
                return 'Estonia';
            case 'lt-LT':
            case 'en-LT':
                return 'Lithuania';
            case 'lv-LV':
            case 'en-LV':
                return 'Latvia';
            default:
                return 'Germany';
        }
    }
    get salesAreaId(): string {
        return this.plant + '/01/01';
    }

    set salesAreaName(value: string | null) {
        this._salesAreaName = value;
    }

    get plant(): string | null {
        if (this._plant) {
            return this._plant;
        }

        switch (this.lang) {
            case 'de-DE':
                return '0500';
            case 'de-AT':
                return '5000';
            case 'fr-FR':
            case 'en-FR':
                return '0600';
            case 'fr-BE':
            case 'nl-BE':
                return '6000';
            case 'en-US':
                return '8100';
            case 'en-CA':
                return '9900';
            case 'it-IT':
            case 'en-IT':
                return '7300';
            case 'tr-TR':
            case 'en-TR':
                return '8000';
            case 'cs-CZ':
            case 'en-CZ':
                return '9700';
            case 'sk-SK':
            case 'en-SK':
                return '7900';
            case 'pt-PT':
            case 'en-PT':
                return '7100';
            case 'es-ES':
            case 'en-ES':
                return '7100';
            case 'ro-RO':
            case 'en-RO':
                return '7700';
            case 'uk-UA':
            case 'en-UA':
                return '9200';
            case 'bg-BG':
            case 'en-BG':
                return '7800';
            case 'sl-SI':
            case 'en-SI':
                return '7500';
            case 'hr-HR':
            case 'en-HR':
                return '7600';
            case 'hu-HU':
            case 'en-HU':
                return '9800';
            case 'sr-RS':
            case 'en-RS':
                return '7400';
            case 'et-EE':
            case 'en-EE':
                return '8700';
            case 'lt-LT':
            case 'en-LT':
                return '8700';
            case 'lv-LV':
            case 'en-LV':
                return '8900';
            default:
                return '0500';
        }
    }

    private _configLanguage: string | null;
    get configLanguage(): string | null {
        return this._configLanguage || this.lang.replace('-', '_');
    }
    set configLanguage(value: string | null) {
        this._configLanguage = value;
    }
}
