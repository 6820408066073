import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QuestConfig, QuestModule } from '@vi/quest';
import { environment } from 'src/environments/environment';
import { ConfigitApiService } from './configit-quest-adapter/configit-api.service';
import { ConfigitQuestAdapterService } from './configit-quest-adapter/configit-quest-adapter.service';
import { ConflictingValueDialogComponent } from './configit-quest-adapter/conflicting-value-dialog/conflicting-value-dialog.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HowToComponent } from './how-to/how-to.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, environment.translations, '/download?file_format=i18next');
}

// quest config
const questConfig: QuestConfig = {
    context: environment.quest.context,
    behavior: {
        allowAnswerReset: true,
        answerResetLabel: '-',
        nextOnWizard: true,
        disableOnCheck: true,
        hideEmptyGroups: false,
        wizardFooter: true,
        showTextInsteadOfGroupTooltip: true,
    },
    icons: {
        enabled: false,
    },
    hint: {
        useDialog: 1,
    },
};

@NgModule({
    declarations: [
        ConfigurationComponent,
        ConflictingValueDialogComponent,
        HowToComponent,
        AppComponent,
        MaintenanceComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FlexModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        QuestModule.forRoot(questConfig),
        MatCardModule,
        MatSnackBarModule,
        MatIconModule,
        NoopAnimationsModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatProgressBarModule,
        AppRoutingModule,
    ],
    providers: [ConfigitQuestAdapterService, ConfigitApiService, TranslateService],
    bootstrap: [AppComponent],
})
export class AppModule {}
