<div class="container">
    <h1>ProductFinder</h1>

    <h3>Introduction</h3>
    <p>The ProductFinder is designed for usage as iFrame. Therefore is a special setup needed.</p>

    <h2>General information</h2>

    <h3>Project stages</h3>
    <p>There are three project stages</p>
    <ul>
        <li>
            development =
            <a href="https://product-finder-integration.viessmann.com" target="_blank"
                >https://product-finder-integration.viessmann.com</a
            >
        </li>
        <li>
            staging =
            <a href="https://product-finder-staging.viessmann.com" target="_blank"
                >https://product-finder-staging.viessmann.com</a
            >
        </li>
        <li>
            prod =
            <a href="https://product-finder.viessmann.com" target="_blank">https://product-finder.viessmann.com</a>
        </li>
    </ul>

    <h3>Boilerplate's</h3>

    <h4>Header</h4>

    <p>
        The ProductFinder contains responsive components which only works in an iFrame if the
        <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Viewport_meta_tag" target="_blank">
            viewport meta tag</a
        >
        is set.
    </p>

    <pre class="codeBlock">
        <code>
            {{boilerplate}}
        </code>
    </pre>

    <h4>iFrame implementation</h4>
    <p>Please notice the ProductFinder URL. There are currently two important query params.</p>

    <ul>
        <li>lang (mandatory)</li>
        <li>redirect (optional)</li>
    </ul>

    <p>
        The query parameter "lang" controls the translation and configuration of possible questions and is therefore
        mandatory.
    </p>

    <p>Currently supported ISO-Codes:</p>

    <ul>
        <li>de-DE</li>
        <li>de-AT</li>
        <li>en-FR</li>
        <li>fr-FR</li>
        <li>nl-BE</li>
        <li>fr-BE</li>
        <li>en-US</li>
        <li>en-CA</li>
        <li>it-IT</li>
        <li>en-IT</li>
        <li>es-ES</li>
        <li>en-ES</li>
        <li>ro-RO</li>
        <li>en-RO</li>
        <li>tr-TR</li>
        <li>en-TR</li>
        <li>cs-CZ</li>
        <li>en-CZ</li>
        <li>sk-SK</li>
        <li>en-SK</li>
        <li>pt-PT</li>
        <li>en-PT</li>
        <li>uk-UA</li>
        <li>en-UA</li>
        <li>bg-BG</li>
        <li>en-BG</li>
        <li>sl-SI</li>
        <li>en-SI</li>
        <li>hr-HR</li>
        <li>en-HR</li>
        <li>hu-HU</li>
        <li>en-HU</li>
        <li>sr-RS</li>
        <li>en-RS</li>
        <li>et-EE</li>
        <li>en-EE</li>
        <li>lt-LT</li>
        <li>en-LT</li>
        <li>lv-LV</li>
        <li>en-LV</li>
    </ul>

    <p>
        As already mentioned the ProductFinder is designed for the usage as iFrame in several applications. So when the
        ProductFinder is finished and the submit-button is pressed there will be a redirect in the top window location
        with all relevant user assignments as query param if the query param "redirect" is set. If not the ProductFinder
        will fire a postMessage() event with all relevant user assignments as JSON to the implementing application
    </p>

    <pre class="codeBlock">
                <code>
                    {{iFrame}}
                </code>
            </pre>

    <h4>iFrame styling</h4>
    <p>Following styling is recommended for displaying the ProductFinder iFrame in fullscreen mode</p>

    <pre class="codeBlock">
                <code>
                    {{styling}}
                </code>
            </pre>

    <h4>ProductFinder event handling</h4>
    <p>
        As alternative for submitting the assignments as query params there is also a postMessage event for fetching the
        assignments in the implementing application, if the query param "redirect" is not set.
    </p>
    <ul>
        <li>
            submit button clicked - contains currently all assignments of an user after completing the ProductFinder
        </li>
    </ul>

    <pre class="codeBlock">
                <code>
                    {{code}}
                </code>
            </pre>

    <h4>Example assignments as query params</h4>

    <pre class="codeBlock">
                <code>
                    {{'\n' + assignmentsQuery}}
                </code>
    </pre>

    <h4>Example assignments as JSON</h4>

    <pre class="codeBlock">
                <code>
                    {{'\n' + (assignmentsJSON | json)}}
                </code>
    </pre>
</div>
