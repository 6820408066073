import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HowToComponent } from './how-to/how-to.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { maintenanceGuard } from './guards/maintenance.guard';
import { MaintenanceComponent } from './maintenance/maintenance.component';

const routes: Routes = [
    { path: '', component: ConfigurationComponent, canActivate: [maintenanceGuard] },
    { path: 'how-to', component: HowToComponent },
    { path: 'maintenance', component: MaintenanceComponent },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
