import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-component',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        const parameters = new URLSearchParams(window.location.search);
        const lang = parameters.get('lang') || this.translate.getBrowserCultureLang();
        if (lang) {
            this.translate.setDefaultLang(lang);
        }
    }
}
